<template>
  <main class="color-primary h-100 blank-aside-js " 
    :class="{ 'blank-aside': isOpenAside == 'true', page_bg_grey: !isNew,  'bg-green4': isNew}"
  >
    <p class="main-title weight-700" v-show="!isNew">
      {{ $t('MANAGEMENT.MANUAL_TICKETS') }}
    </p>
    <p class="main-title weight-700" v-show="isNew">
      {{ $t('MANAGEMENT.NEW_MANUAL_TICKETS') }}
    </p>
    <section class="content-margin">
      <div class="d-flex m-b-20 filter-block">
        <div class="px15 filter-form w-100 m-b-15">
          <label>{{ $t('MANAGE_PRODUCT.SELECT_PROVIDER') }}</label>
          <vue-select 
            :options="filter.suppliers.options" 
            class="input cus-select"
            v-model="filter.suppliers.selected"
            @input="changeSupplier"
          ></vue-select>
        </div>
        <div class="px15 filter-form w-100 m-b-15">
          <label>{{ $t('MANAGE_PRODUCT.CHOOSE_PRODUCT') }}</label>
          <vue-select 
            :options="filter.supplierProducts.options" 
            class="input cus-select"
            v-model="filter.supplierProducts.selected"
          ></vue-select>
        </div>
      </div>

      <div class="m-b-30 d-flex flex-wrap">
        <div class="d-flex align-items-center px15">
          <v-checkbox 
            color="#0D3856" 
            class="cus-checkbox" 
            v-model="filter.isActive"
            id="isActive"
            @change="changeFilterCheck1($event)"
          ></v-checkbox>
          <label class="px10" for="isActive">{{ $t('MANAGEMENT.ACTIVE_CARDS') }}</label>
        </div>
        <div class="d-flex align-items-center px15">
          <v-checkbox 
            color="#0D3856" 
            class="cus-checkbox"
            v-model="filter.isUsed" 
            id="isUsed"
            @change="changeFilterCheck1($event)"
          ></v-checkbox>
          <label class="px10" for="isUsed">{{ $t('MANAGEMENT.TICKETS_USED') }}</label>
        </div>
        <div class="d-flex align-items-center px15">
          <v-checkbox 
            color="#0D3856" 
            class="cus-checkbox" 
            v-model="filter.aboutToExpire" 
            id="aboutToExpire"
            @change="changeFilterCheck1($event)"
          ></v-checkbox>
          <label class="px10" for="aboutToExpire">{{ $t('MANAGEMENT.TICKETS_ABOUT_EXPIRE') }}</label>
        </div>
        <div class="d-flex align-items-center px15">
          <v-checkbox 
            color="#0D3856" 
            class="cus-checkbox" 
            v-model="filter.expiredAndUnused" 
            id="expiredAndUnused"
            @change="changeFilterCheck2($event)"
          ></v-checkbox>
          <label class="px10" for="expiredAndUnused">{{ $t('MANAGEMENT.EXPIRED_UNUSED_CARDS') }}</label>
        </div>
      </div>

      <div class="d-flex flex-wrap">
        <div class="px15">
          <label>{{ $t('MANAGEMENT.DATE_OF_INCOME') }}</label>
          <div class="d-flex align-items-center p-relative m-t-5 m-b-15">
            <Datepicker
              format="d MMMM yyyy"
              class="input cus-datepicker"
              :language="languages[currentLang]"
              v-model="filter.startDate"
            ></Datepicker>
            <img
              src="/assets/img/icons/calendar-alt-light.svg"
              alt=""
              class="calendar-img"
            />
          </div>
        </div>
        <div class="px15">
          <label>{{ $t('REPORT_SALES.END_DATE') }}</label>
          <div class="d-flex align-items-center p-relative m-t-5 m-b-15">
            <Datepicker
              format="d MMMM yyyy"
              class="input cus-datepicker"
              :language="languages[currentLang]"
              v-model="filter.endDate"
            ></Datepicker>
            <img
              src="/assets/img/icons/calendar-alt-light.svg"
              alt=""
              class="calendar-img"
            />
          </div>
        </div>
        <div class="px15">
          <label></label>
          <!-- <button class="filter__button m-t-5" @click="testAlertTwo()"></button> -->
          <button class="filter__button m-t-5" @click="filterData()"></button>
        </div>
      </div>

      <div class="px15 m-t-20">
        <button class="primary-btn btn-limit-width bg-yellow" @click="newManualCard()" v-show="!isNew">
          {{ $t("COMMON.NEW") }}
        </button>
      </div>
      <div class="px15" v-show="isNew">
        <div class="manual-card-edit">
          <div class="d-flex flex-wrap">
            <div class="w-25 px15 m-b-10 validation-form-group"
              :class="{ 'form-group--error': $v.createData.card_number.$error }"
            >
              <label>{{ $t('TOUR_ORDER.TICKET_NUMBER') }} *</label>
              <input 
                type="text" 
                class="input m-t-5" 
                v-model="createData.card_number" 
                @keypress="isNumber($event)"
                @input="$v.createData.card_number.$touch"
              />
              <div class="color-red1 fs12 valide-error-msg" v-if="!$v.createData.card_number.required">
                {{ $t('VALIDATION.REQUIRED_FIELD') }}
              </div>
            </div>
            <div class="w-25 px15 m-b-10 validation-form-group"
              :class="{ 'form-group--error': $v.createData.code.$error }">
              <label>{{ $t('GIFTCARD_PAYMENT_RESPUB.PURCHASE_EXTERNAL_ID') }} *</label>
              <input 
                type="text" 
                class="input m-t-5"  
                v-model="createData.code"
                @keypress="isNumber($event)"
                @input="$v.createData.code.$touch"
              />
              <div class="color-red1 fs12 valide-error-msg" v-if="!$v.createData.code.required">
                {{ $t('VALIDATION.REQUIRED_FIELD') }}
              </div>
            </div>
            <div class="w-25 px15 m-b-10">
              <label>{{ $t('MANAGEMENT.EXPIRY_DATE') }}</label>
              <Datepicker
                format="d MMMM yyyy"
                class="input cus-datepicker m-t-5"
                :language="languages[currentLang]"
                v-model="createData.expiry_date"
              ></Datepicker>
            </div>
            <div class="w-25 px15 m-b-10">
              <label>{{ $t('MANAGEMENT.USED') }}</label>
              <input type="text" class="input m-t-5" :value="$t('COMMON.NO')" disabled/>
            </div>
          </div>
          <div class="d-flex">
            <button class="primary-btn btn-limit-width mx15" @click="createCards()">
              {{ $t('COMMON.SAVE') }}
            </button>
            <button class="primary-btn btn-limit-width mx15 bg-red2" @click="closeCreatePage()">
              {{ $t('MANAGE_SHANIV.VOID') }}
            </button>
          </div>
        </div>
      </div>
    </section>
    <section class="px15" v-show="!isNew">
      <div class="table-content-part m-t-40">
        <div class="d-flex justify-content-between">
          <div class="search-bar">
            <p class="search-label">{{ $t('COMMON.SEARCH') }}</p>
            <v-text-field
              v-model="search"
              single-line
              hide-details
              class="search-form"
            ></v-text-field>
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="manualCards"
          class="cus-table editable-table main-table striped-table"
          item-key="id"
          :expanded.sync="expanded"
        >
          <template v-slot:[`item.status`]="{ item }">
            <div style="display: flex; justify-content: center;" v-if="item.status == 2">
              <v-checkbox color="#0D3856" class="cus-checkbox" value input-value="true"></v-checkbox>
            </div>
            <div style="display: flex; justify-content: center;" v-else>
              <v-checkbox color="#0D3856" class="cus-checkbox" value></v-checkbox>
            </div>
          </template>
          <template v-slot:[`item.supplier_id`]="{ }">
            {{ filter.suppliers.selected.code }}
          </template>
          <template v-slot:[`item.del`]="{ item }">
            <div class="d-flex justify-content-center align-items-center">
              <div class="red-close_btn pointer" @click="deleteCard(item.id)">
                <img src="/assets/img/icons/cross-red.svg" alt=""/>
              </div>
            </div>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-icon medium color="dark" @click="expanded = [item]" v-if="!expanded.includes(item)">edit</v-icon>
            <v-icon medium color="dark" @click="expanded = []" v-if="expanded.includes(item)">edit</v-icon>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="manual-card-edit">
              <div class="d-flex flex-wrap">
                <div class="w-25 px15 m-b-10">
                  <label>{{ $t('TOUR_ORDER.TICKET_NUMBER') }}</label>
                  <input type="text" class="input m-t-5" v-model="item.card_number"  />
                </div>
                <div class="w-25 px15 m-b-10">
                  <label>{{ $t('GIFTCARD_PAYMENT_RESPUB.PURCHASE_EXTERNAL_ID') }}</label>
                  <input type="text" class="input m-t-5" v-model="item.code" />
                </div>
                <div class="w-25 px15 m-b-10">
                  <label>{{ $t('MANAGEMENT.EXPIRY_DATE') }}</label>
                  <Datepicker
                    format="d MMMM yyyy"
                    class="input cus-datepicker m-t-5"
                    :language="languages[currentLang]"
                    v-model="item.expiry_date"
                  ></Datepicker>
                </div>
                <div class="w-25 px15 m-b-10">
                  <label>{{ $t('MANAGEMENT.USED') }}</label>
                  <input v-if="item.status == 2" type="text" class="input m-t-5" :value="$t('COMMON.NO')" disabled/>
                  <input v-else type="text" class="input m-t-5" :value="$t('COMMON.YES')" disabled/>
                </div>
              </div>
              <div class="d-flex">
                <button class="primary-btn btn-limit-width mx15" @click="updateCards(item)">
                  {{ $t('COMMON.SAVE') }}
                </button>
                <button class="primary-btn btn-limit-width mx15 bg-red2" @click="expanded = []">
                  {{ $t('MANAGE_SHANIV.VOID') }}
                </button>
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
    </section>
    <ConfirmModal @confirmData="confirmData">
      <template v-slot:modal-content>
        {{ $t('ALERT.DELETE_CONFIRM_TXT', {NAME: ""}) }}
      </template>
    </ConfirmModal>
    <loading
      :active.sync="supplier_loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main> 
</template>

<script>
import { mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ConfirmModal from '../../../components/management/ConfirmModal.vue';

export default {
  name: 'CPManualCards',
  mixins: [validationMixin],
  components: {
    ConfirmModal
  },
  data() {
    return {
      search: '',
      expanded: [],
      headers: [
        {
          text: this.$t('REPORT_SALES.SUPPLIER_ID'),
          align: 'center',
          sortable: false,
          value: 'supplier_id',
        },
        {
          text: this.$t('DASHBOARD.PRODUCT_NAME'),
          align: 'center',
          sortable: false,
          value: 'product_id',
        },
        {
          text: this.$t('MANAGEMENT.INSERTED_BY'),
          align: 'center',
          sortable: false,
          value: 'author',
        },
        {
          text: this.$t('MANAGEMENT.DATE_TIME_OF_INCOME'),
          align: 'center',
          sortable: false,
          value: 'insert_date',
        },
        {
          text: this.$t('MANAGEMENT.EXPIRY_DATE'),
          align: 'center',
          sortable: false,
          value: 'expiry_date',
        },
        {
          text: this.$t('TOUR_ORDER.TICKET_NUMBER'),
          align: 'center',
          sortable: false,
          value: 'code',
        },
        {
          text: this.$t('REPORT_SALES.GIFT_CARD_CODE'),
          align: 'center',
          sortable: false,
          value: 'card_number',
        },
        {
          text: this.$t('MANAGEMENT.USED'),
          align: 'center',
          sortable: false,
          value: 'status',
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'edit',
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'del',
        }
      ],
      isNew: false,
      filter: {
        suppliers: {
          options: [],
          selected: ''
        },
        supplierProducts: {
          options: [],
          selected: ''
        },
        isActive: false,
        isUsed: false,
        aboutToExpire: false,
        expiredAndUnused: false,
        startDate: "",
        endDate: ""
      },
      createData: {
        card_number: "",
        code: "",
        expiry_date: ""
      },
      deleteId: ""
    }
  },
  validations: {
    createData: {
      card_number: {
        required
      },
      code: {
        required
      }
    }
  },
  computed: {
    ...mapState({
      supplier_loading: (state) => state.supplier.loading,
      supplier_items: state => state.supplier.supplier_items,
      special_products: state => state.supplier.special_products,
      manualCards: state => state.category_products.manualCards,
    }),
  },
  async created () {
    await this.getAllSupplierItems();
    if (this.supplier_items) {
      this.filter.suppliers = {
        options: [],
        selected: ''
      };
      this.supplier_items.prepaid.forEach(el => {
        this.filter.suppliers.options.push({
          code: el.id, 
          label: el.name
        });
      });
     this.filter.suppliers.selected = this.filter.suppliers.options[0];
    }
    this.changeSupplier();
    this.filter.startDate = new Date();
    this.filter.endDate = new Date();
    let d = new Date();
    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();
    this.createData.expiry_date = new Date(year + 1, month, day);
  },
  methods: {
    // ...mapActions("alertTwo", {
    //   successAlert: "success"
    // }),
    ...mapActions("supplier", {
      getAllSupplierItems: "getAllSupplierItems",
      getProductsById: 'getProductsById',
    }),
    ...mapActions("category_products", {
      getManualCards: "getManualCards",
      createManualCards: "createManualCards",
      deleteManualCards: "deleteManualCards",
      updateManualCards: "updateManualCards"
      
    }),
    newManualCard() {
      this.isNew = true;
      document.getElementsByTagName("html")[0].style.background = "#A7FFD0";
    },
    closeCreatePage() {
      this.isNew = false;
      document.getElementsByTagName("html")[0].style.background = "#dfe0e3";
      this.$v.$reset();
      this.createData.card_number = "";
      this.createData.code = "";
      this.createData.expiry_date = new Date();;
    },
    // testAlertTwo() {
    //   this.successAlert( this.$t("MANAGEMENT.CARD_WAS_CREATED_SUCCESSFULLY") )
    // },
    async changeSupplier() {
      const currentSupplierId = this.filter.suppliers.selected.code;
      await this.getProductsById({
        supplier_id: currentSupplierId
      });
      this.filter.supplierProducts = {
        options: [],
        selected: ''
      };
      if (this.special_products.manual) {
        this.special_products.manual.forEach(el => {
          this.filter.supplierProducts.options.push(
            {
              label: el.name, 
              code: el.id
            }
          )
        });
      }
      this.filter.supplierProducts.selected = this.filter.supplierProducts.options[0];
    },
    changeFilterCheck1(e) {
      if (e) {
        this.filter.expiredAndUnused = false;
      }
    },
    changeFilterCheck2(e) {
      if (e) {
        this.filter.isActive = false;
        this.filter.isUsed = false;
        this.filter.aboutToExpire = false;
      }
    },
    filterData() {
      const id = this.filter.supplierProducts.selected.code;
      let params = {};
      params.startDate = this.$moment(this.filter.startDate).format('YYYY-MM-DD');
      params.endDate = this.$moment(this.filter.endDate).format('YYYY-MM-DD');
      params.isUsed = this.filter.isUsed;
      params.isActive = this.filter.isActive;
      params.aboutToExpire = this.filter.aboutToExpire;
      params.expiredAndUnused = this.filter.expiredAndUnused;

      this.getManualCards({
        id: id,
        params: params
      })
    },
    async createCards() {
      this.$v.$touch();
      if (
        this.$v.$anyError
      ) {
        return;
      }

      const id = this.filter.supplierProducts.selected.code;
      let params = {};
      params.card_number = this.createData.card_number;
      params.code = this.createData.code;
      params.expiry_date = this.$moment(this.createData.expiry_date).format('YYYY-MM-DD');
      params.product_id = id;

      await this.createManualCards({params: params});
      this.closeCreatePage();
      this.filterData();
    },
    async deleteCard(id) {
      this.deleteId = id;
      this.$modal.show('confirmModal');
    },
    async confirmData() {
      const id = this.deleteId;
      await this.deleteManualCards(id);
      this.filterData();
      this.$modal.hide('confirmModal');
    },
    async updateCards(item) {
      let params = {};
      params.card_number = item.card_number;
      params.code = item.code;
      params.expiry_date = this.$moment(item.expiry_date).format('YYYY-MM-DD');
      params.product_id = this.filter.supplierProducts.selected.code;
      params.status = item.status;
      await this.updateManualCards({
        id: item.id,
        params: params
      });
      this.filterData();
    }
  }
}
</script>

<style lang="scss" scoped>
  label {
    margin-bottom: 0;
    height: 24px;
    display: block;
  }
  .filter-block {
    flex-wrap: wrap;
    .filter-form {
      max-width: 350px;
    }
  }

  .filter__button {
    width: 56px !important;
  }

  .calendar-img {
    width: 22px;
    position: absolute;
    top: 7px;
    left: 7px;
  }
  .editable-table.v-data-table tbody tr td.manual-card-edit:last-child {
    padding: 10px !important;
  }
  .manual-card-edit {
    background-color: #90C9E1;
    padding: 10px !important;
    label {
      font-size: 16px;
      height: 22px;
    }
    .input {
      height: 36px;
    }
    .primary-btn {
      height: auto;
      padding: 0px 35px;
      min-width: auto;
      font-size: 16px;
    }
    .vdp-datepicker {
      position: initial;
    }
  }

@media screen and (max-width: 635px) {
  .w-25 {
    width: 100% !important;
  }
}
.ltr-type {
  .calendar-img {
    left: unset;
    right: 7px;
  }
}
</style>